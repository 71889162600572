<section class="flex-col justify-center align-center">
  <h3>Frequently Asked Questions</h3>

  <h4>General Stuff</h4>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What is the Top Escape Rooms Project?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        The <b>Top Escape Rooms Project</b> is an attempt to find the very best escape rooms in the world
        by leveraging the experience of the most experienced escape room enthusiasts in the world.  We
        serve escape room owners and designers by publicly recognizing their achievements, and we serve our
        fellow enthusiasts by providing a global bucket list of the best rooms to play.
      </p>
      <p>
        The project started in 2018 and in its first year, it gave awards to the top 25 escape rooms
        and top 10 companies, based on the opinions of 70 contributors. It has grown larger and larger
        each year since, and in 2023, the project had over 1100 contributors from 32 different countries, and
        we gave awards to the top 100 escape rooms.
      </p>
      <p>
        To check out the awards from previous years, along with loads more info on who all participated and the
        underlying methodology, visit the main
        <a href="https://www.terpeca.com" target="_blank">TERPECA website</a>.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          OK, so then what's TERPECA mean?
        </mat-panel-title>
      </mat-expansion-panel-header>
        <p>
          Well, TERP is for <b>Top Escape Rooms Project</b>, ECA is for <b>Enthusiasts' Choice Award</b>,
          and <b>TERPECA</b> is the acronym we use to refer as shorthard for a single award, as in "I really
          think my local escape room deserves to win a TERPECA", or as a reference to the entire project, as in "I
          can't wait to see the TERPECA results this year!".  It's really quite versatile, and this is only
          scratching the surface for the many ways you can use it -- try it for yourself!
        </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Great, I'm sold on the name.  But how do you pronounce TERPECA exactly?
        </mat-panel-title>
      </mat-expansion-panel-header>
        <p>
          I'm so glad you asked! It is pronounced <b>TUR-puh-kuh</b> (i.e. /<b>ˈtər</b>-pə-kə/), said with the stress
          on the first syllable, similar to (and rhyming with) the words "silica", "replica", and "Seneca".
        </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Alright, so how does the process work?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        I won't go into all the details here, as you can certainly read more at the main
        <a href="https://www.terpeca.com" target="_blank">TERPECA website</a>, but
        basically the process is broken down into two phases, a <b>nomination phase</b> and a
        <b>ranking phase</b>.
      </p>
      <p>
        In the <b>nomination phase</b>, our most experienced participants nominate the rooms
        that they feel are the most worthy of winning a TERPECA.</p>
      <p>
        After the nomination phase, any room with a minimum number of nominations moves on to the
        <b>ranking phase</b>, in which participants must stack rank all the nominated rooms based
        on their own experiences, and of course <i>they can only rank rooms that
        they have personally played</i>.
      </p>
      <p>
        Once the ranking is all done, we have a bunch of ordered lists of rooms by a bunch of super
        experienced players, and then we <b>crunch the numbers</b> with some pretty advanced math to output
        a final ranked list that we believe most accurately reflects the opinions of our voters, and
        then we give awards to the ones that finish the highest.  Pretty cool, eh?
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Aside from contributing, how else can I help the project?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You can always help the project just by spreading the word to other enthusiasts, whether that's letting them know about TERPECA to use as a
        resource for finding world-class games, or encouraging them to participate as a contributor.
      </p>
      <p>
        Escape room owners can help the project by printing and displaying one or both of our promotional posters, which can be found
        <a href="https://drive.google.com/drive/folders/1hTU9vfiZ7y7hZfHyHYFYWZjJWEdEFqDi?usp=sharing" target="_blank">here</a>. One of the posters is
        targeted to a general audience and promotes the main TERPECA website as a resource, while the other is targeted specifically to those players who are
        eligible to sign up and contribute.
      </p>
      <p>
        If you have won a TERPECA in the past, the general audience poster could be a way to point people to the site where they can see your accolades and
        find other world-class rooms to play. If you're aspiring to win a TERPECA, the voting poster might be a great way to let the players that love your
        room know how they can sign up.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          I own a room that I think belongs on this list.  How do I get considered for this project?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        There is no way for a room owner or affiliate to apply to be part of the project. Instead, all nominations must come unsolicited from our nominators,
        which are players that have played at least 200 rooms AND are willing to declare that your room is one of the very best they've ever played. As soon
        as any of our nominators has played your room, or if someone that has already played your room subsequently becomes a nominator, then you have officially
        "applied" to be included in the project.
      </p>
      <p>
        Also note that while it is strictly forbidden to solicit nominations or favorable rankings from our contributors in any way, you are welcome to invite
        TERPECA contributors to come play your room, and you can find the list of these folks on our main website in the "Contributors" section. We currently
        have hundreds of nominators from all over the world, but some areas are represented better than others, and we're constantly working to improve that.
        You can help with this; if someone plays your room that has played over 200 rooms and they aren't yet a nominator, send them our way to sign up to be one!
        You might even consider printing one of our promotional posters described in the previous question to help raise awareness for the project and recruit
        contributors that you know have played your rooms.
      </p>
      <p>
        As for the specific room requirements, see the next question.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel id="eligibility" [(expanded)]="eligibilityExpanded" (afterExpand)="expanded('eligibility')" (afterCollapse)="collapsed('eligibility')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What criteria are used to determine if a room is eligible for a Top Room award?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        In order for a specific room to be eligible for nomination as a Top Room award, it must meet two criteria:
      </p>
      <p>
        &bull; The room must be <b>open to the public for at least 30 days between November 1 of the previous year
        and October 31 of the current year</b>.
      </p>
      <p>
        &bull; The room must be <b>reasonably playable by English-speaking players for at least 30 days between November 1 of the previous year and October 31
        of the current year</b>. Furthermore, in regions where English availability cannot be assumed, <b>this must be clearly designated at the company's website
        and/or linked booking platform, and once a room is available in English, its operator must intend to continue offering English-language play as long as
        the room is open.</b> Also note that if there is language availability information listed both on a room's primary website and on any booking platforms
        where the room can be booked, the English availability information must be consistent across all such sources.
      </p>
      <p>
        The main inference of the two rules above is that in order for a room to be eligible in a given year, it must be open no later than October 1 of that
        year, with English language availability, and if it opens as late as October 1, it must also be bookable every day that month to meet the 30 day minimum.
      </p>
      <p>
        In order to be sure you are in compliance with the these rules above, we recommend that you make a social media post announcing your room launch that includes your
        first bookable date and your English availability prior to October 1 so that there is no ambiguity regarding your eligibility.
      </p>
      <p>
        Note that we may retroactively remove rooms from the results if they become unavailable in English. We respect the difficulty of creating and operating an escape
        room in multiple languages, and we know that glitches or brief periods of unavailability may happen despite best efforts and intentions, and we will never remove
        a result lightly. However, we are dedicated to honoring great rooms which are available to the international English-speaking community,
        and to recognizing operators committed in good faith to serving that community. We realize this "good faith intention" rule is subjective;
        anyone with questions or concerns should contact us.
      </p>
      <p>
        Beyond these criteria, it is up to the participating nominators to decide if a room is worthy for nomination or not, as well as the
        very question of what even should count as an escape room.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What's new for 2024?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        <b>We will once again be awarding 100 Top Room awards.</b> In 2022, we increased the number of Top Room awards to 100 and discontinued the Top
        Company and Top Online Room categories. We plan to keep this focus on only the Top Room awards for the foreseeable future.
      </p>
      <p>
        <b>We no longer support Facebook Login.</b> Unfortunately, Facebook changed their policy so that it is no longer possible for independent
        developers to use Facebook Login unless they are associated with a registered business, so unfortunately we can no longer use that service.
        If you used Facebook login, please email rich&#64;terpeca.com from the email you'd like to use to login with going forward, and he'll migrate your account.
      </p>
      <p>
        <b>We are lowering the maximum number of nominations per nominator from 11 to 10.</b> In 2022, we introduced the concept of automatic finalists
        for the first time, and lowered the maximum nomination count from 20 to 15. In 2023, we lowered the maximum nomination count again to 11. This year
        we are lowering it a final time to the nice round number of 10, with the expectation that an increase in new nominators will help offset the impact
        of that reduction, while putting an end to the question of why we had such an odd number last year. We do not expect to change this threshold again
        in the future.
      </p>
      <p>
        <b>We will continue to collect data during the voting phase on horror preferences.</b> It is no secret that horror-themed rooms have historically
        done well in the TERPECA awards, and there are many theories as to why this is the case. We are still processing the data we collected on this
        metric last year, and will publish a report on our findings soon. Please note that again, this data will not be used in ranking, as it is strictly
        to help us understand the landscape of preferences and priorities.
      </p>
      <p>
        <b>We have added the ability to specify your account on many of the common room-tracking websites in your TERPECA profile.</b>
        If you use any of EscapeTheReview.co.uk (UK), EscapeAll.gr (Greece), EscapeGame.fr (France), Escape-Roomers.com (Italy), EscapeTalk.nl (BeNeLux),
        Lockme (Poland), Morty.app (USA), or The-Escapers.com (France), you can visit your profile and add this information to your account! This is
        especially useful for new applicants in helping us verify your experience, but we also hope to set up some deeper integrations with these sites
        in the future, and this is the first step in that direction. If you use a public site to track your games that isn't on this list, let us know
        and we will consider adding it to the list!
      </p>
      <p>
        While the issues listed above are the most major changes, we're also constantly improving the system. This includes changes to make it
        easier for ambassadors to process applicants, improvements to the nomination and ranking flows, and possibily even more improvements to
        the algorithm that leads to the final results. We listen carefully to all the feedback we have received about all aspects of the project,
        and are constantly trying to make things better.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What are the important dates to know for 2024?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        <b>Applications</b> are live year-round!
      </p>
      <p>
        <b>Nominations</b> start in October and are due October 31 at 11:59 PM Pacific.
      </p>
      <p>
        <b>Rankings</b> start in November and are due November 30 at 11:59 PM Pacific.
      </p>
      <p>
        <b>The 2024 TERPECA Winners</b> will be announced via a live internet broadcast on December 21 at 1:00 PM Pacific. More details will
        be provided as the date approaches - be sure to follow us on <a href="https://www.facebook.com/terpeca/" target="_blank">Facebook</a> and
        <a href="https://www.instagram.com/topescaperoomsproject/" target="_blank">Instagram</a> for updates.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What if my question isn't answered in this FAQ?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        If you're not yet a member of the <a href="https://escaperooms.fun/" target="_blank">Escape Rooms Discord</a>,
        join over there and drop in to the #terpeca channel where there are folks discussing this project all the time.
      </p>
      <p>
        Otherwise, if you have questions of a more sensitive nature, or you just prefer email, you can send mail to Rich
        at rich&#64;terpeca.com.
      </p>
    </mat-expansion-panel>
  </mat-accordion>

  <h4>The Application Process</h4>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          How does the application process work?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Check out our <a href="https://www.youtube.com/watch?v=juX7x-2gOhU" target="_blank">How to Apply</a>
        video for a walkthrough of the application process. Otherwise, read the questions below for more specifics.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What are the requirements to participate this year?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        A new applicant is required to have played <b>at least 100 rooms</b> to become a TERPECA voter. Furthermore, if you have played
        <b>at least 200 rooms</b>, you can also be approved to nominate rooms. Note that in keeping consistent with past years, we
        <i>do</i> allow applicants to include live-hosted online games in their count for determining eligibility, and if you have been
        approved as a nominator or voter in a prior year, you will still be eligible to participate at that level going forward even
        if you qualified with fewer rooms than are now required for new applicants.
      </p>
      <p>
        We do not expect the nominator threshold of 200 or the voter threshold of 100 to change in the foreseeable future.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel id="what-counts" [(expanded)]="whatCountsExpanded"
                         (afterExpand)="expanded('what-counts')" (afterCollapse)="collapsed('what-counts')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What counts as an escape room for my reported count?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        For the purposes of TERPECA, <b>you may count any in-person experience that you believe a reasonable enthusiast would likely also count</b>,
        and <b>you should include any hosted escape games played online using the same criteria</b>.  See the next question for more details
        on what you may or may not include among games in the online category.
      </p>
      <p>
        If you do not have an exact count, you may submit an estimate to the nearest round number, but be sure to <b>round down</b> and DO NOT
        submit an estimate that might actually be higher than the actual number of rooms that you have played.
      </p>
      <p>
        If you have a borderline case that would make the difference between whether or not you reach the voter or nominator threshold, feel free to use
        your best judgment and then mention the specifics in your application.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel id="what-counts-online" [(expanded)]="whatCountsOnlineExpanded"
                         (afterExpand)="expanded('what-counts-online')" (afterCollapse)="collapsed('what-counts-online')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What counts as an *online* escape room for my reported count?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        We allow users to also count <b>live-hosted online escape-room-like experiences</b> that are in the style that became popular during
        the pandemic. Specifically, the rooms you may count for online escape rooms should generally fit the 'remote avatar' or 'actor/audio' categories on
        <a href="https://escapethereview.co.uk/location/play-at-home/" target="_blank">Escape the Review</a>. Hybrid or genre-busting experiences may be
        counted if they match the spirit described above, but you may not include boxed-game, book-based, print-and-play, or pure-video-game
        experiences towards your count.
      </p>
      <p>
        If you have a borderline case that would make the difference between whether or not you reach the voter or nominator threshold, feel free to use
        your best judgment and then mention the specifics in your application.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel id="experience" [(expanded)]="experienceExpanded" (afterExpand)="expanded('experience')" (afterCollapse)="collapsed('experience')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          How do I provide evidence of my experience?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        We are very strict about making sure you've reached the required number of rooms played to be a nominator or voter, but we are flexible in how
        you might convey to us the list of rooms you've played.
      </p>
      <p>
        Many enthusiasts keep their own spreadsheets tracking their play, and this is the most common type of evidence we see. If you
        have a spreadsheet or a list of games you've played, our preferred option is that you upload it to Google Drive and then put a link to that doc in the
        "Room count evidence" field in your application. (Don't forget to make the link public if you do this!) Alternatively, you can also send any
        evidence you have as an attachment to admin&#64;terpeca.com.
      </p>
      <p>
        Many enthusiasts keep track of their games via an online tool or app, and that's great, too! In fact, if you use EscapeTheReview.co.uk (UK),
        EscapeAll.gr (Greece), EscapeGame.fr (France), Escape-Roomers.com (Italy), EscapeTalk.nl (BeNeLux), Lockme (Poland), Morty.app (USA), or
        The-Escapers.com (France), please include your userid for this site in your application, and if all your games are tracked there, that can be used
        as evidence. It there are other similar regional sites that tracks your play and has public profiles, you can simply provide a link to your profile
        where we can see your list.
      </p>
      <p>
        If you don't keep a spreadsheet or track your games in the ways described above, we have seen other creative solutions that we are happy to
        consider. Some applicants have sent us photo albums of all their post-game photos, or a link to their Instagram account where they post about
        each game they play, or even pictures from a party in which they celebrated their milestone game.
      </p>
      <p>
        If none of the above is possible for you, do your best to provide whatever evidence you do have of your experience, and we will take a look!
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          How do you plan to use the information I give you in the application?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        The goal of the application has several purposes. The first purpose is to determine whether you meet our experience criteria, and for which bucket (i.e. nominator
        or voter). The second purpose is to determine your credibility as a live human that has the experience you claim to have if you are someone that none of us personally
        know. The third purpose is to provide information we can use in a short bio about you for the results website, which will go live when we announce results.
      </p>
      <p>
        We will treat the personal information you provide in the application with the utmost care.  Any contact information you provide will be kept strictly
        confidential and will be used only to get in touch with you about things directly related to this project, and for no other reasons.  We also obviously won't
        share it with anyone.
      </p>
      <p>
        If you are accepted as a contributor to the project and end up participating as a nominator or voter, we do reserve the right to publish your name, your home city,
        your claimed room count, your required disclosures on any escape rooms with which you are affiliated, an alphabetized list of the rooms you claim to have played
        by nominating or ranking them, and the bio that you provide.
      </p>
      <p>
        If you provide your own bio, we will likely use that or a reasonably edited version of it on the website, similar to the bios we had last year for our participants.
        Unless you tell us not to, we may augment your bio with interesting information you provide elsewhere in your application, save for any of the obviously sensitive
        contact information.  If you have any questions or concerns about how we might use the information you provide, just let us know and we'll be understanding.
      </p>
      <p>
        Finally while the application is meant to be done with care, it isn't meant to be an obstacle.  If you're someone that should be in this program, do your best,
        and we'll be reasonable.
      </p>
      <p>
        Oh, and before you stress too much over ranking your category preferences on the last page of the application, know that that's really just there to get you
        some practice with the UI we'll be using for ranking, so do put <i>some</i> thought into that, but don't get bogged down by it!
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel id="bio" [(expanded)]="bioExpanded" (afterExpand)="expanded('bio')" (afterCollapse)="collapsed('bio')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What needs to be in my bio?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Your bio should consist of a short 1-3 sentence paragraph, written in English, that should convey who you are and the role you play in the escape room community.
        This will ultimately be posted on the public-facing TERPECA website and it should be written in complete sentences and be reasonably well written.
      </p>
      <p>
        This is your chance to let people know who you are and why you're here.  If you work in the escape room industry, mention where you work and what your role
        is. If you have any interesting escape room-related accomplishments, mention them. If you run a website or blog, please include a link, but your bio shouldn't
        be <i>only</i> a link.
      </p>
      <p>
        If you work in the escape room industry, there may be information that you <b>must</b> disclose in your bio.  See the question on
        <a [routerLink]="['.']" fragment="conflicts" (click)="scrollToFragment('conflicts')">conflicts of interest</a> for details.
      </p>
      <p>
        If you need ideas or examples, check out the bios from previous years at <a href="https://www.terpeca.com/">terpeca.com</a>.
        If English is not your first language, feel free to write your bio in your native language, and then translate it to English using Google Translate to submit.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          OK, I think I'm a great candidate for this, how do I sign up?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Just click on the <a routerLink="/">Apply</a> link above, fill out the form to the best of your ability, and we'll get back to you as
        quickly as possible.  If you think you might be eligible to nominate, though, don't delay, as nominations close October 31!
      </p>
      <p>
        If you've played enough rooms to participate, but aren't sure whether to do it or not, please do it!  You'll be giving back to the
        creators of the best games by recognizing them for their efforts, while also helping to provide a valuable resource for fellow
        enthusiasts.  The value of these awards depends on your participation to maximize its value!
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          I don't personally have enough experience yet, but are there still ways I can help?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Absolutely!  You can help us find the people that <b>should</b> be participating and encourage <i>them</i> to join.
      </p>
      <p>
        Please try to encourage to join anyone you know that has played at least 100 rooms, and <i>especially</i> if they've played over 200.
        The results get better the more people we have that meet the bar, and there are a lot of them out there that we just need to
        find and get on board.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can I edit my application after I submit, but before it gets reviewed?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Yes! Once you submit, you can still go back and make changes, right up until a decision is made. You can also edit any of your public-facing
        data after you've been approved.
      </p>
    </mat-expansion-panel>

  </mat-accordion>

  <h4>The Nomination Process</h4>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          How does the nomination process work?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Check out our <a href="https://www.youtube.com/watch?v=gDaMt8iQNSk" target="_blank">How to Nominate</a>
        video for a walkthrough of the nomination process.  Otherwise, read the questions below for more specifics.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What criteria should I use to nominate a room?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        The general rule of thumb for choosing which rooms to nominate is to consider <b>what rooms that you've played that you would most
        likely recommend to an enthusiast similar to yourself, if they could play any room in the world</b>.  The rooms you nominate should
        also generally adhere to the heuristics we've defined for
        <a [routerLink]="['.']" fragment="what-counts" (click)="scrollToFragment('what-counts')">regular escape rooms</a>
        and
        <a [routerLink]="['.']" fragment="what-counts-online" (click)="scrollToFragment('what-counts-online')">online escape rooms</a> above.
        Beyond that, there are some other rules and guidelines to follow, which I'll outline next, and are also listed on the nomination page.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What are the rules for nomination?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        <b>First and foremost, remember that this is an individual process.</b> Your nominations are your own, and they should be
        unique to you. Do not discuss your specific nominations with anyone else until you've submitted,
        and do not submit nominations on behalf of anyone but yourself.
      </p>
      <p>
        <b>The goal here is to identify the world's best escape rooms</b> to both honor those who
        create them and to recommend the best experiences to other avid players. If you're here, it
        means you're an experienced player yourself, so in general, <b>it is up to you to decide what counts as an
        escape room</b> (or a online escape room) and whether or not it deserves this form of recognition, but
        you can also refer to the heuristics we've defined above for
        <a [routerLink]="['.']" fragment="what-counts" (click)="scrollToFragment('what-counts')">regular escape rooms</a>
        and
        <a [routerLink]="['.']" fragment="what-counts-online" (click)="scrollToFragment('what-counts-online')">online escape rooms</a>.
      </p>
      <p>
        Any room you submit must have been <b>open AND playable in English for at least 30 days between November 1,
        {{year-1}} and October 31, {{year}}.</b>  You don't have to have played it during that window, but it needs to have
        been open during that window. For more details on this rule, see the
        <a [routerLink]="['.']" fragment="eligibilityExpanded" (click)="scrollToFragment('eligibility')">room eligibility</a>
        section above.
      </p>
      <p>
        <b>You absolutely may not nominate any room with which you have any direct affiliation, past or
        present.</b> This includes owners, designers, employees, investors, consultants, etc. You obviously
        also can't accept any favors or rewards in exchange for nominating a room. <i>If you work in the
        escape room industry and have existing business relationships with escape room companies, be sure to
        read the question about
        <a [routerLink]="['.']" fragment="conflicts" (click)="scrollToFragment('conflicts')">conflicts of interest</a>
        before making any nominations on rooms from companies where you have such relationships.</i>
      </p>
      <p>
        <b>If the same game exists in multiple places, report where <i>you</i> played it.</b> If the game has multiple
        versions, report which version you played. We'll figure out how to sort out this issue in the next phase
        of the process.
      </p>
      <p>
        If the same game exists as both an in-person escape game and an online escape game, <b>you can only
        nominate that game for a given category if you played the version of the game in that category</b>. Since
        the online room category was removed in 2022, it means you must have played the in-person version of
        a game in order to nominate it.
      </p>
      <p>
        As this is a global award, we intend to include rooms not targeted primarily to English speakers.
        However, because our website and its audience are primarily English-speaking, and because our algorithm relies on
        direct comparisons between rooms that may not be possible without a common language, we require that any room
        nominated <b>is playable by English-speaking players</b>, and that in cases where English availability cannot be
        assumed, <b>the website must mention that the room is playable in English.</b>
      </p>
      <p>
        <b>As of 2024, you are able to nominate a maximum of 10 rooms.</b> We have reduced this number from past years while also automatically
        advancing each of last year's Top Room winners as finalists if they are still eligible this year. The rationale for this change
        is to reduce the incentive for nominators to game the system by not nominating rooms they are sure to advance to the voting round.
      </p>
      <p>
        <b>Do not game the system</b> and assume any specific room will be nominated by others.  In the vast majority of cases,
        the rooms you nominate should end up being ranked higher than rooms you chose not to nominate.
      </p>
      <p>
        While you are limited to a maximum number of nominations, <b>do not feel obligated to use them all</b> if you
        feel like you don't have enough rooms that are worthy of this award.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Why do last year's Top Rooms automatically qualify as finalists?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        When a room wins a Top Room award, past years have demonstrated that so long as it remains eligible, it is a sure bet to
        make it through to the finals again the following year. Nominators know this too, so it then becomes tempting not to spend
        valuable nominations on rooms that are sure to get plenty of nominations from others. Thus, by automatically granting
        finalist status to rooms that won the previous year, it allows nominators to avoid that temptation and to focus their
        nominations on the rest of the eligible candidates. This effectively increases the nominating power for those
        who have played many of the winners and may have otherwise spent their nominations on those, and it reduces the incentive
        to game the system by holding back nominations for rooms that are expected to have plenty of support.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Will my nominations be kept private?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Absolutely positively yes. Privacy in both the nomination and ranking process is a critical part of this project,
        and a feature that we take extremely seriously. The project falls apart without the trust of those
        participating.
      </p>
      <p>
        The only person that can possibly ever see your nominations in such a way where you name is still attached is Rich,
        and he will only do so in extreme cases if necessary for debugging or to investigate any anomalies in the data.
        Otherwise, your nominations in their raw form will only be seen by some subset of the board members during the process
        in which we need to group them and count them in preparation for phase 2, and during this phase your personal data
        will be stripped, so no one will be able to tell who made a given nomination. And no one outside the board will ever
        see your raw nomination data in any form.
      </p>
      <p>
        The only exception to this is if you provide a quote to support a specific room that ends up winning an award, and
        we end up choosing that quote to use, you will be attributed for that quote, and from that people will be able to
        imply that you nominated that room.  Quotes during nomination are optional, though, so if you don't want to be
        attributed, don't bother with the quotes.
      </p>
      <p>
        If you have any concerns about how your data will be handled, please contact Rich directly to discuss further.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel id="horror" [(expanded)]="horrorExpanded"
                         (afterExpand)="expanded('horror')" (afterCollapse)="collapsed('horror')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What does a room's "horror level" mean?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        By popular demand, starting in 2022, we are marking rooms to indicate whether they are horror/scary experiences:
      </p>
      <ul>
        <li *ngFor="let level of allHorrorLevels" [value]="level">
          <app-horrorlevel [level]="level" [showLongText]="true"></app-horrorlevel>
        </li>
      </ul>
      <p>
        These levels are meant as a rough guide for quick reference, not a nuanced exploration of the horror dimension.
        Enthusiasts who do or don't enjoy horror-type experiences can use these flags to get a general sense of a room's
        flavor, but should do their own research to confirm if the experience is truly one they would enjoy.
      </p>
      <p>
        For rooms without an existing confirmed horror level, we are asking nominators to pick a horror level they feel
        describes the room. We will review a room's nominations (and do additional research, if needed) to assign
        a final horror level.
      </p>
      <p>
        Horror levels do not affect ranking in any way, they are strictly informational.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can I change my nominations after I submit, but before the deadline?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Yes! Once you submit, you can still go back and make changes, right up until the deadline, but at that point, they'll be locked in.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          I'm done with my nominations, now what?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Sit tight and wait for the ranking phase to start. Or help recruit more nominators!  Either way, we'll email you when the ranking phase starts.
      </p>
    </mat-expansion-panel>
  </mat-accordion>

  <h4>The Voting Process</h4>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          How does the voting process work?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Check out our <a href="https://www.youtube.com/watch?v=d3NALWbQyrk" target="_blank">How to Vote</a>
        video for a walkthrough of the voting process.  Otherwise, read the questions below for more specifics.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What criteria should I use to rank a room?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        The general rule of thumb for ranking rooms is to consider <b>which rooms you are more likely to recommend to an enthusiast similar to
        yourself</b>.
      </p>
      <p>
        Ranking is hard, we know.  Sometimes it will be hard to separate groups, but it helps to think of two rooms at a time
        and decide which you think is better, even if by a tiny amount.  But ultimately we expect there will be signal there, and if two rooms
        have to be differentiated somehow, it is better to be done by someone that has played them both, so do your best.
      </p>
      <p>
        If you were a nominator, you have a great place to start, as any of the rooms you nominated should be at the top of your list!
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          What are the rules for ranking?
        </mat-panel-title>
      </mat-expansion-panel-header>
        <p>
          <b>First and foremost, remember that this is an individual process.</b> Your rankings are your own, and they should be
          unique to you. Do not discuss your specific rankings with anyone else until you've submitted, and do not submit rankings
          on behalf of anyone but yourself.
        </p>
        <p>
          The goal of the ranking phase is for you to <b>submit a single, stack-ranked list</b> of all the nominated rooms that
          you've played, with the one listed first being the best room you've played and going down from there.
        </p>
        <p>
          <b>You can only rank games you've played.</b> This is super important and there are no exceptions. In particular, if the same game
          exists as both an in-person escape game and an online escape game, you can <b>only</b> rank that game in a given category if you
          played the game in the version corresponding to that category. Since the online room category was removed in 2022, it means you must
          have played the in-person version of a game in order to rank it.
        </p>
        <p>
          <b>You absolutely may not rank any room with which you have any direct affiliation, past or present, and during the
          ranking process you must disclose any affiliations you have with any of the nominated rooms.</b> This includes owners, designers,
          employees, investors, consultants, etc.  You obviously also can't accept any favors or rewards in exchange for ranking a room.
          <i>If you work in the escape room industry and have existing business relationships with escape room companies, be sure to
          read the question about
          <a [routerLink]="['.']" fragment="conflicts" (click)="scrollToFragment('conflicts')">conflicts of interest</a>
          before ranking any rooms from companies where you have such relationships.</i>
        </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Will my rankings be kept private?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Absolutely positively yes. Privacy in both the nomination and ranking process is a critical part of this project,
        and a feature that we take extremely seriously. The project falls apart without the trust of those
        participating.
      </p>
      <p>
        <b>That said, while the <i>rankings</i> you provide will be strictly confidential, the <i>list of rooms that you rank</i>
        WILL be public.</b> When you rank a room, it means that you are indicating that you have personally played that room AND that
        you are not affiliated with it in any way as an owner, designer, employee, consultant, etc. This piece of information is critical
        for accountability purposes and should be no more controversial than your photo being shown on social media after completing a game,
        or writing a review for a game you've played.  <i>If you're not comfortable with us publishing the list of rooms you claim to have
        played and ranked (without revealing anything about how you ranked them), then you should not participate.</i>
      </p>
      <p>
        The only person that can possibly ever see your rankings in such a way where you name is still attached is Rich,
        and he will only do so in extreme cases if necessary for debugging or to investigate any anomalies in the data.
        Otherwise, your rankings will only be seen in anonymized form by some subset of the board members during the process
        of evaluating that the results are subjectively consistent with the data output from the final data processing step.
        And no one outside the board will ever see your ranking data in any form.
      </p>
      <p>
        If you have any concerns about how your data will be handled, please contact Rich directly to discuss further.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          WTF!? How did that room make it to the rankings phase, while this other much better room didn't?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        First off, opinions differ; a room you don't like at all may be loved by others.  It can also sometimes happen in cases where some nominators
        may have played most of their 200+ rooms in only their local market, so their list of nominations might end up with nominations that wouldn't show
        up on the lists of people that played in more or stronger markets. Just be sure to rank it accordingly and trust in the system that it will
        end up in the relative position in the rankings that it deserves.
      </p>
      <p>
        As for why a given room wasn't a finalist, it simply means that either it didn't meet the requirements to be included (i.e. open for 30+ days in
        the past year and playable in English), or it simply didn't have enough nominators that thought it was one of the best rooms they've ever
        played. Keep in mind that we're only giving official recognition to the rooms that finish in the top 100 spots; we don't claim that the entire list of
        finalists is the authoritative top list of rooms, and the system is designed to maximize the likelihood that only the very very best rooms aren't missed.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can I change my rankings after I submit, but before the deadline?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Yes!  You can reopen your rankings if you want to make changes before the deadline, but be sure to resubmit again or they won't be counted
        in the final results.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          I'm done with my rankings, now what?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Once you've submitted your rankings, you will access to review the public data that will be made available about you and all of the other
        participants that have submitted their rankings. Check it out and make sure everything looks right.  If it doesn't, reopen your rankings
        and make any edits you want to make, then be sure to resubmit or your rankings won't be counted in the final results.
      </p>
      <p>
        Aside from that, you're done for this year, now just sit back and wait to see the results. We'll announce the results during our live show in
        December and have the website updated shortly after.
      </p>
      <p>
        Thank you for helping us in our mission to find the very best escape rooms in the world.
        We couldn't do it without people like you and we are very grateful for your contribution.
      </p>
    </mat-expansion-panel>
  </mat-accordion>

  <h4>Ethics and Integrity</h4>
  <mat-accordion>
    <mat-expansion-panel id="conflicts" [(expanded)]="conflictsExpanded" (afterExpand)="expanded('conflicts')" (afterCollapse)="collapsed('conflicts')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What are the rules regarding conflicts of interest?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        As the global escape room community is rather interconnected and many owners and suppliers are also some of the world's biggest enthusiasts, we believe
        that <b>owners and suppliers that are also escape room enthusiasts can and should participate</b>.  At the same time, the reputation and relevance of the project
        critically requires that all project participants act in good faith and nominate and rank rooms with <b>as little personal and professional bias as possible</b>
        and <b>as much transparency as is reasonable</b>. As such, we offer the following guidelines for participation for those who work in the industry.
      </p>
      <p>
        Before getting into specifics, the first guiding principle is that <b>if you do not feel you can nominate or rank a room without significant personal or
        professional bias, or if you think that a reasonable person would believe that you are not able to do so, then you should not nominate or rank a given room.</b>
        In particular, <b>taking money or favors in exchange for a nomination or favorable ranking is explicitly forbidden, and would be grounds for immediate
        expulsion from the project.</b> Beyond that, the rules below govern what you <b>must</b> disclose and what you <b>must not</b> nominate or rank. It is always
        fine to abstain from nominating or ranking a given room if you feel that you have significant bias.
      </p>
      <div>
        <ul class="flex-col gap-5">
          <li>
            <b>Owners, employees, investors, designers, consultants, and other direct affiliates of a given escape room or escape room company, past or present,
            as well as spouses, partners, or family members of any such affiliates, must disclose any such affiliations</b> in their application, in their public-facing
            bio, and when explicitly disclosing affiliations during the ranking phase. They also <b>absolutely must not nominate or rank</b> any such affiliated
            room or company.
          </li>
          <li>
            <b>Owners or investors of businesses that sell props or other supplies that are used in escape rooms, but do not otherwise directly profit from the success
            of a given room or company</b>, may still nominate and rank rooms from companies that employ their services, however they <b>must disclose in their bio
            that they provide such services</b>  and whether or not they have provided such services to any of the rooms they've nominated or voted on.  It is not
            necessary for them to list all the nominated rooms or companies which employ their services.
          </li>
          <li>
            <b>Owners or investors of businesses that provide an ongoing service to an escape room company <i>whose profits are directly related to the sales</i>
            of a given room or company</b>, such as a booking platform, <b>must disclose in their bio that they provide such services</b> in general and
            <b>must not nominate or rank</b> any rooms that employ that business's services.  It is not necessary for them to list all the specific
            rooms which employ their services, but it is necessary that they not nominate or rank these rooms.
          </li>
          <li>
            <b>Owners or investors of businesses that provide an ongoing service which applies only to a specific subset of rooms or experiences that a company offers</b>,
            such as a VR or AR company, <b>may still nominate or rank rooms from that company <i>that do not use that service</i></b>, however they <b>must disclose in their bio
            that they provide such services</b> and they <b>must not nominate or rank any of the rooms or experiences <i>that do use their services</i></b>. It is not
            necessary for them to list all the nominated rooms or companies which employ their services.
          </li>
        </ul>
      </div>
      <p>
        Clearly there are many grey areas here, so if you have a relationship or situation that you're not sure how to treat, let us know at admin&#64;terpeca.com and we'll
        be happy to provide specific guidance for your situation.
      </p>
      <p>
        Finally, <b>the board reserves the right to disqualify the contribution of any participant that appears to be putting their own or their business's interest above
        the interest of the quality and success of the project.</b> Along these lines, if you feel that any of the other participants are not offering the level of transparency
        and integrity that we're asking for, please let us know, anonymously if necessary, and we will investigate.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can I mention my TERPECA contributor status to get discounts or perks?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        No. Your TERPECA status is not secret, but you may not use TERPECA status to request discounts, free plays, special booking or other favors.
        Doing so might make people think that owners exchange favors for TERPECA ranking, that voters get a different experience than other players,
        or that people participate in TERPECA for perks rather than to ensure fair results. Even if you might be able to ignore favors when ranking,
        reviewer favor would be corrosive to the process overall.
      </p>
      <p>
        The board reserves the right to disqualify the contribution of any participant that appears to be putting their own interest above the interest of the
        quality and success of the project.
      </p>
      <p>
        If you are an owner that feels that a TERPECA contributor has abused their status with you, we take this very seriously. Please contact Rich directly
        at rich&#64;terpeca.com to report any such behavior, and reports will be handled with the utmost discretion.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can my business offer discounts or perks to TERPECA contributors?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        We discourage companies from explicitly offering discounts or other perks for TERPECA contributors, however, we do not explicitly forbid it. If you as a
        business want to offer discounts or perks to TERPECA contributors, you may do so, but <b>it may not be in response to a request from a player</b> (see
        previous question), and <b>it must be made abundantly clear that it comes with no expectation whatsoever of a nomination or favorable ranking</b>.
      </p>
      <p>
        The board reserves the right to disqualify any company, even retroactively, that appears to be putting their own interest above the interest of the
        quality and success of the project.
      </p>
      <p>
        If you are ever asked by an owner to nominate them or rank them highly in exchange for discounts or perks, we take this very seriously. Please
        contact Rich directly at rich&#64;terpeca.com to report any such behavior, and reports will be handled with the utmost discretion.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel id="privacy-policy" [(expanded)]="privacyPolicyExpanded" (afterExpand)="expanded('privacy-policy')" (afterCollapse)="collapsed('privacy-policy')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What is your privacy policy?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        If you sign in with a third-party provider, such as Apple, Facebook, or Google, we will use the connection to the account on the service you use <i>only</i> to identify
        who you are and to connect you to the account data that you provide. At any time, you may request that we delete your connection to these third-party providers and we will
        do so permanently.
      </p>
      <p>
        If you apply to join the project, you will provide information about yourself and your experience playing escape rooms. We will use this data to determine whether you
        meet the qualifications necessary to participate in this project. Any personal information you provide in the application will be handled with the utmost care. Any contact
        information you provide will be kept strictly confidential and will be used only to get in touch with you about things directly related to this project, and for no other
        reasons. We also obviously won't share it with anyone. If you choose to no longer participate in the project, we will delete any of your private contact information upon
        request.
      </p>
      <p>
        If you are accepted as a contributor to the project and end up participating as a nominator or voter, you will submit nominations and/or ranked lists of the escape rooms
        you have played. We will use the data you submit to determine which escape rooms will be honored in the year's awards. The specific nominations and/or rankings that you
        submit will be kept strictly confidential (and see the sections on these above for more details). However, we do reserve the right to publish your name, your home city,
        your claimed room count, your required disclosures on any escape rooms with which you are affiliated, an alphabetized list of the rooms you claim to have played by nominating
        or ranking them, and the bio that you provide. Furthermore, once you have participated in a given award year, your contributions for that year will be kept indefinitely
        as a historical record, with anything that was originally private being kept private and anything that was originally public being kept public. If you have extenuating
        circumstances that require your name be removed from the public contributors lists in past years, let us know, and we will do our best to honor any reasonable requests.
      </p>
      <p>
        As of 2024, we offer the ability for you to include your userid on any of several popular room-tracking websites. If you choose to include your userid on one or more of these
        sites, it will not be shared publicly, but we do reserve the right to let any of the websites you include know that you are a TERPECA contributor. If we do so, we will only
        share your userid on their site with them, and we will not include any of your other private data.
      </p>
      <p>
        To request data deletion or for any specific questions about our privacy policy, contact admin&#64;terpeca.com.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Who's on the 2024 executive advisory board?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        This year's executive advisory board consists of <b>Rich Bragg</b> (USA), <b>Sarah Dodd</b> (UK), <b>Dan Egnor</b> (USA),
        <b>Victor Espriu and Maria Navarro</b> (Spain), <b>Errol Elumir</b> (Canada), <b>Ken Ferguson</b> (UK),
        <b>David and Lisa Spira</b> (USA), and <b>Heiner Stepen</b> (Germany). Each of these people are highly connected and highly respected
        members of the international escape room community.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Who are the 2024 regional ambassadors?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Our regional ambassador program is critical to the growth and success of the project, and our ambassadors help make sure the project
        thrives throughout the various places in the world where there are English-available escape rooms. Here is the list of
        ambassadors for the 2024 award season.
      </p>
      <p>
        <b>Lee-Fay Low</b> (Australia)<br>
        <b>Stefan Bauer</b> (Austria)<br>
        <b>Dimitar Smilyanov &amp; Nadezhda Danabasheva</b> (Bulgaria, Croatia, Hungary, Romania, Serbia, Slovenia)<br>
        <b>Patrick Pilon</b> (Canada)<br>
        <b>Jonas Rasmussen</b> (Denmark, Finland, Iceland, Norway, Sweden)<br>
        <b>Melissa Faucher</b> (France)<br>
        <b>Heiner Stepen</b> (Germany)<br>
        <b>Elli Kouliadelli &amp; Georgina Vanikioti</b> (Greece)<br>
        <b>Ron Kagan</b> (Israel)<br>
        <b>Alessandro Crepaldi &amp; Chiara Tesi</b> (Italy)<br>
        <b>Santiago Onel</b> (Belgium, Netherlands, Luxembourg)<br>
        <b>Wojtek Nadara</b> (Poland)<br>
        <b>Victor Espriu</b> (Spain)<br>
        <b>Ryan Driver</b> (Switzerland)<br>
        <b>Mairi Nolan</b> (United Kingdom)<br>
        <b>John Devendorf</b> (United States)
      </p>
      <p>
        If you have any questions about the project, please feel free to get in touch with the ambassador from your nearest region! Or, if you
        live in an area where there is no ambassador, and you have a recommendation for someone who would be a good choice, let us know at
        admin&#64;terpeca.com. Ambassadors must be enthusiasts first and cannot be affiliated with any escape room businesses, and must participate
        in the project as a contributor for at least one year before being eligible to become an ambassador.
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</section>
