import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription, timer } from 'rxjs';
import { debounce, pairwise, startWith } from 'rxjs/operators';

import { TerpecaRankedEntity } from 'src/app/models/results.model';
import { HorrorLevel, TerpecaCategory, TerpecaRoom, allHorrorLevels } from 'src/app/models/room.model';
import { SettingsService } from 'src/app/services/settings.service';
import {
    RoomFilter, getCountriesForRoom, getLanguages, getLocationString, getStateName, getStatesForRoom, isFinalist, isNominee,
    isPermanentlyIneligible, isWinner, languageName, numNominations
} from 'src/app/utils/misc.utils';
import { compareEntitiesByName } from 'src/app/utils/sorting.utils';

@Component({
  selector: 'app-roomfinder',
  templateUrl: './roomfinder.component.html',
  styleUrl: './roomfinder.component.css'
})
export class RoomFinderComponent implements OnInit, OnDestroy {
  allCountries = [];
  allStates = [];
  allProvinces = [];
  allHorrorLevels = allHorrorLevels;
  allLanguages = [];
  isWinner = isWinner;
  isFinalist = isFinalist;
  isNominee = isNominee;
  isClosed = isPermanentlyIneligible;
  languageName = languageName;
  locationName = getLocationString;
  getStateName = getStateName;
  loadingInProgress = false;
  dataSource = null;
  hasUnrankedRooms = false;
  hasDuplicateRanks = false;
  lastYearSort = 0;
  numRoomsShowing = 0;
  numRoomsLoaded = 0;
  allRooms: TerpecaRoom[] = [];
  roomFilter: RoomFilter = new RoomFilter();
  languageSet = new Set<string>();
  countrySet = new Set<string>();
  stateSet = new Set<string>();
  provinceSet = new Set<string>();
  roomColumns: string[] = ['rank', 'room', 'company', 'city', 'country', 'tags', 'lastRank'];

  formGroup: UntypedFormGroup;
  subscription: Subscription;

  constructor(public settings: SettingsService, private db: AngularFirestore) { }

  // eslint-disable-next-line @angular-eslint/no-async-lifecycle-method
  async ngOnInit() {
    const defaultYear = this.allYears()[0];
    this.formGroup = new UntypedFormGroup({
      year: new UntypedFormControl(defaultYear),
      countryFilter: new UntypedFormControl(''),
      stateFilter: new UntypedFormControl(''),
      horrorLevelFilter: new UntypedFormControl(allHorrorLevels),
      languageFilter: new UntypedFormControl(''),
      searchText: new UntypedFormControl('')
    });
    this.formGroup.disable();
    await this.loadRooms();
    await this.updateDataSource();
    this.formGroup.enable();
    this.formGroup.markAsDirty();
    this.subscription = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      pairwise(),
      debounce(([previous, current]) => (
        // eslint-disable-next-line no-constant-binary-expression
        (this.loadingInProgress = true) &&
        previous.searchText !== current.searchText ? timer(500) : timer(0)))
    ).subscribe(async () => {
      this.updateDataSource();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async loadRooms() {
    this.loadingInProgress = true;
    await this.db.collection<TerpecaRoom>('rooms').ref
    .where('category', '==', TerpecaCategory.TOP_ROOM).get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      for (const doc of snapshot.docs) {
        const room: TerpecaRoom = doc.data();
        if (!room.isNominee?.length) {
          continue;
        }
        room.docId = doc.id;
        this.allRooms.push(room);
        const countries = getCountriesForRoom(room);
        for (const country of countries) {
          this.countrySet.add(country);
          if (country === 'Canada') {
            const states = getStatesForRoom(room, country);
            for (const state of states) {
              this.provinceSet.add(state);
            }
          } else if (country === 'United States') {
            const states = getStatesForRoom(room, country);
            for (const state of states) {
              this.stateSet.add(state);
            }
          }
        }
        const languages = getLanguages(room);
        for (const language of languages) {
          if (language !== 'en') {
            this.languageSet.add(language);
          }
        }
      }
      this.allRooms.sort(this.compareByScore(this.formGroup.value.year));
      this.lastYearSort = this.formGroup.value.year;
      this.numRoomsLoaded = this.allRooms.length;
      this.allCountries = Array.from(this.countrySet.values()).sort();
      this.allStates = Array.from(this.stateSet.values()).sort((a, b) => getStateName(a).localeCompare(getStateName(b)));
      this.allProvinces = Array.from(this.provinceSet.values()).sort((a, b) => getStateName(a).localeCompare(getStateName(b)));
      this.allLanguages = Array.from(this.languageSet.values()).sort((a, b) => languageName(a).localeCompare(languageName(b)));
    });
    this.loadingInProgress = false;
  }

  async updateDataSource() {
    if (this.lastYearSort !== this.formGroup.value.year) {
      this.lastYearSort = this.formGroup.value.year;
    }
    let filteredSource = this.allRooms.filter(r => this.matchesFilters(r));
    filteredSource = this.roomFilter.transform(
      filteredSource, this.formGroup.value.countryFilter, this.formGroup.value.stateFilter, this.formGroup.value.searchText, false,
      this.compareByScore(this.formGroup.value.year));
    this.numRoomsShowing = filteredSource.length;
    this.updateAnomalyTags(filteredSource, this.formGroup.value.year);
    this.dataSource = new MatTableDataSource(filteredSource);
    this.loadingInProgress = false;
  }

  updateAnomalyTags(rooms: TerpecaRoom[], year) {
    let hasDuplicateRanks = false;
    let hasUnrankedRooms = false;
    let lastRank = 0;
    for (const room of rooms) {
      if (isFinalist(room, year) && room.resultsData && room.resultsData[year]) {
        const entity: TerpecaRankedEntity = room.resultsData[year];
        if (entity.unranked) {
          hasUnrankedRooms = true;
        } else if (entity.rank) {
          if (entity.rank === lastRank) {
            hasDuplicateRanks = true;
          }
          lastRank = entity.rank;
        }
      }
    }
    this.hasDuplicateRanks = hasDuplicateRanks;
    this.hasUnrankedRooms = hasUnrankedRooms;
  }

  matchesFilters(room: TerpecaRoom) {
    if (!isNominee(room, this.formGroup.value.year)) {
      return false;
    }
    if (!this.matchesHorrorLevelFilter(this.formGroup.value.horrorLevelFilter, room)) {
      return false;
    }
    if (!this.matchesLanguageFilter(this.formGroup.value.languageFilter, room)) {
      return false;
    }
    return true;
  }

  matchesHorrorLevelFilter(filter: HorrorLevel[], room: TerpecaRoom) {
    return filter?.length === allHorrorLevels.length || filter?.includes(room.horrorLevel || HorrorLevel.UNKNOWN);
  }

  matchesLanguageFilter(filter: string, room: TerpecaRoom) {
    if (!filter) {
      return true;
    }
    return getLanguages(room).includes(filter);
  }

  horrorLevel(room: TerpecaRoom) {
    return room.horrorLevel || HorrorLevel.UNKNOWN;
  }

  languages(room: TerpecaRoom) {
    return getLanguages(room);
  }

  compareByScore(year) {
    return (a: TerpecaRoom, b: TerpecaRoom) => {
      if (isFinalist(a, year) && isFinalist(b, year)) {
        if (!a.resultsData || !a.resultsData[year]) {
          return -1;
        }
        if (!b.resultsData || !b.resultsData[year]) {
          return 1;
        }
        if (b.resultsData[year].score !== a.resultsData[year].score) {
          return b.resultsData[year].score - a.resultsData[year].score;
        }
        return a.resultsData[year].rank - b.resultsData[year].rank;
      }
      if (isFinalist(a, year)) {
        return -1;
      }
      if (isFinalist(b, year)) {
        return 1;
      }
      const aNoms = numNominations(a, year);
      const bNoms = numNominations(b, year);
      if (aNoms !== bNoms) {
        return bNoms - aNoms;
      }
      return compareEntitiesByName(a, b);
    };
  }

  allYears() {
    return this.settings.finishedYears().slice().reverse();
  }
}
