import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import app from 'firebase/compat/app';
import { Subscription } from 'rxjs';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { maxQuotesPerYear } from 'src/app/models/quote.model';
import {
    TerpecaRanking, UnrankedReason, UnrankedReasonEntry, UnrankedReasonMap, getUnrankedIds, getUnrankedReasonDescription,
    getUnrankedReasonEmoji
} from 'src/app/models/ranking.model';
import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { ApplicationStatus, HorrorPreference, TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { RoomCountErrorStateMatcher, roomCountValidator } from 'src/app/utils/form.utils';
import * as utils from 'src/app/utils/misc.utils';
import { compareEntitiesByLocation, compareEntitiesByName, compareRoomsByCompany, compareUsingMap } from 'src/app/utils/sorting.utils';
import { ConfirmListDialogComponent } from 'src/app/views/confirmlistdialog/confirmlistdialog.component';
import { environment } from 'src/environments/environment';

import { SimpleDialogComponent } from '../simpledialog/simpledialog.component';
import { UnrankRoomDialogComponent } from '../unrankroomdialog/unrankroomdialog.component';

@Component({
  selector: 'app-rank',
  templateUrl: './rank.component.html',
  styleUrl: './rank.component.css'
})
export class RankComponent implements OnInit, OnDestroy {
  user: TerpecaUser;
  allRooms: TerpecaRoom[];
  rankableRegularRooms: TerpecaRoom[];
  rankableOnlineRooms: TerpecaRoom[];
  rankableRoomMap: Map<string, TerpecaRoom>;
  allCountryList: string[];
  irlCountryList: string[];
  onlineCountryList: string[];

  currentSortFn: (a: (TerpecaRoom | TerpecaNomination), b: (TerpecaRoom | TerpecaNomination)) => number; // contextual
  currentCategory: TerpecaCategory;      // contextual
  selectedRoomIds: string[];             // contextual
  rankedRoomIds: string[];               // contextual
  unrankedRoomIds: string[];             // contextual
  unrankedReasonMap: UnrankedReasonMap;  // contextual

  regularPlayedIds: Set<string>;
  onlinePlayedIds: Set<string>;
  regularNominationIds: string[];
  onlineNominationsIds: string[];
  previousRegularRanking: TerpecaRanking;
  previousOnlineRanking: TerpecaRanking;

  regularRanking: TerpecaRanking;
  onlineRanking: TerpecaRanking;

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  fourthFormGroup: UntypedFormGroup;
  fifthFormGroup: UntypedFormGroup;
  sixthFormGroup: UntypedFormGroup;
  private regularRankingStepIndex = 3;
  private onlineRankingStepIndex: number;
  addQuotesStepIndex: number;
  reviewAndSubmitStepIndex: number;
  furthestStepReached = 0;
  year = environment.currentAwardYear;
  regularRankingDoc: AngularFirestoreDocument<TerpecaRanking>;
  onlineRankingDoc: AngularFirestoreDocument<TerpecaRanking>;
  private userSubscription: Subscription;
  private regularRankingSubscription: Subscription;
  private onlineRankingSubscription: Subscription;
  hideRules = false;
  confirmedRules = false;
  readyToStart = false;
  readyToRank = false;
  rankingNotes: string[];
  rankingErrors: string[];
  Status = ApplicationStatus;
  roomCountrySort = compareEntitiesByLocation;
  roomNameSort = compareEntitiesByName;
  roomCompanySort = compareRoomsByCompany;
  roomCountErrorMatcher = new RoomCountErrorStateMatcher();
  Category = TerpecaCategory;
  getUnrankedIds = getUnrankedIds;

  @ViewChild('stepper') stepper: MatStepper;
  bioUpdated = false;

  constructor(public auth: AuthService, public settings: SettingsService,
              private db: AngularFirestore, private dialog: MatDialog) {
    this.firstFormGroup = new UntypedFormGroup({
      realName: new UntypedFormControl('', Validators.required),
      roomCount: new UntypedFormControl('', Validators.required),
      virtualRoomCount: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      state: new UntypedFormControl(''),
      city: new UntypedFormControl('', Validators.required),
      bio: new UntypedFormControl('', Validators.required),
      horrorPreference: new UntypedFormControl('', Validators.required),
      videoContributionInterest: new UntypedFormControl('', Validators.required)
    }, roomCountValidator);
    this.secondFormGroup = new UntypedFormGroup({ searchText: new UntypedFormControl(''), countryFilter: new UntypedFormControl('') });  // disclose affiliations
    this.thirdFormGroup = new UntypedFormGroup({ searchText: new UntypedFormControl(''), countryFilter: new UntypedFormControl('') });   // select regular rooms
    this.fourthFormGroup = new UntypedFormGroup({ });  // rank regular rooms
    if (this.settings.hasOnlineRoomCategory()) {
      this.fifthFormGroup = new UntypedFormGroup({ searchText: new UntypedFormControl(''), countryFilter: new UntypedFormControl('') }); // select online rooms
      this.sixthFormGroup = new UntypedFormGroup({ });   // rank online rooms
      this.onlineRankingStepIndex = 5;
      this.addQuotesStepIndex = 6;
      this.reviewAndSubmitStepIndex = 7;
    } else {
      this.onlineRankingStepIndex = -1;
      this.addQuotesStepIndex = 4;
      this.reviewAndSubmitStepIndex = 5;
    }
  }

  ngOnInit() {
    this.userSubscription = this.auth.user.subscribe(async (user: TerpecaUser) => {
      if (user && (!this.user || (user.uid !== this.user.uid))) {
        if (this.regularRankingSubscription) {
          this.regularRankingSubscription.unsubscribe();
        }
        if (this.onlineRankingSubscription) {
          this.onlineRankingSubscription.unsubscribe();
        }
        if (user.status >= ApplicationStatus.VOTER && this.settings.isVotingOpen(user)) {
          console.log(`Loading rooms for ${user.email}`);
          await this.loadRooms();
          console.log('Loading past nominations and rankings');
          this.regularPlayedIds = new Set<string>();
          this.regularNominationIds = await this.loadNominations(user, TerpecaCategory.TOP_ROOM);
          this.previousRegularRanking = await this.loadPreviousRankings(user, TerpecaCategory.TOP_ROOM);
          if (this.settings.hasOnlineRoomCategory()) {
            this.onlinePlayedIds = new Set<string>();
            this.onlineNominationsIds = await this.loadNominations(user, TerpecaCategory.TOP_ONLINE_ROOM);
            this.previousOnlineRanking = await this.loadPreviousRankings(user, TerpecaCategory.TOP_ONLINE_ROOM);
          }
          console.log('Loading rankings');
          await this.loadRankings(user, TerpecaCategory.TOP_ROOM);
          if (this.settings.hasOnlineRoomCategory()) {
            await this.loadRankings(user, TerpecaCategory.TOP_ONLINE_ROOM);
          }
          console.log('Loading complete');
        }
      }
      this.user = user;
      if (user) {
        this.firstFormGroup.patchValue(user);
        this.hideRules = this.confirmedRules = user.rankingRulesConfirmed?.includes(this.year) || false;
      }
      this.readyToStart = true;
    });
  }

  private async loadRooms() {
    if (this.rankableRoomMap) {
      return;
    }
    const allCountrySet = new Set<string>();
    const irlCountrySet = new Set<string>();
    const onlineCountrySet = new Set<string>();
    await this.db.collection<TerpecaRoom>('rooms').ref
    .where('isNominee', 'array-contains', this.year).get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      const rooms: TerpecaRoom[] = [];
      for (const doc of snapshot.docs) {
        const room: TerpecaRoom = doc.data();
        room.docId = doc.id;
        rooms.push(room);
        allCountrySet.add(room.country);
      }
      this.allRooms = rooms;
      const rankableRegularRooms: TerpecaRoom[] = [];
      const rankableOnlineRooms: TerpecaRoom[] = [];
      const rankableRoomMap = new Map<string, TerpecaRoom>();
      for (const room of rooms) {
        if (room.isFinalist?.includes(this.year)) {
          switch (room.category) {
            case TerpecaCategory.TOP_ROOM:
              rankableRegularRooms.push(room);
              irlCountrySet.add(room.country);
              break;
            case TerpecaCategory.TOP_ONLINE_ROOM:
              if (this.settings.hasOnlineRoomCategory()) {
                rankableOnlineRooms.push(room);
                onlineCountrySet.add(room.country);
              }
              break;
          }
          rankableRoomMap.set(room.docId, room);
        }
      }
      this.rankableRegularRooms = rankableRegularRooms;
      this.rankableOnlineRooms = rankableOnlineRooms;
      this.rankableRoomMap = rankableRoomMap;
      this.allCountryList = Array.from(allCountrySet).sort();
      this.irlCountryList = Array.from(irlCountrySet).sort();
      this.onlineCountryList = Array.from(onlineCountrySet).sort();
      console.log(`loaded ${rooms.length} rooms: ${rankableRegularRooms.length} regular rooms and ` +
        `${rankableOnlineRooms.length} online rooms are rankable`);
    });
  }

  private async loadNominations(user: TerpecaUser, category: TerpecaCategory) {
    const nominations: string[] = [];
    await this.db.collection<TerpecaNomination>('nominations').ref
    .where('pending', '==', false)
    .where('category', '==', category)
    .where('userId', '==', user.uid).get()
    .then((snapshot: QuerySnapshot<TerpecaNomination>) => {
      for (const doc of snapshot.docs) {
        const nom: TerpecaNomination = doc.data();
        switch (category) {
          case TerpecaCategory.TOP_ROOM:
            this.regularPlayedIds.add(nom.roomId);
            break;
          case TerpecaCategory.TOP_ONLINE_ROOM:
            this.onlinePlayedIds.add(nom.roomId);
            break;
        }
        if (nom.year === environment.currentAwardYear && !nominations.includes(nom.roomId)) {
          nominations.push(nom.roomId);
        }
      }
    });
    return nominations;
  }

  private async loadPreviousRankings(user: TerpecaUser, category: TerpecaCategory) {
    // When creating a new year's ranking, we find the last ranking of the same type to seed the data.
    let prevRanking: TerpecaRanking = null;
    for (let y = this.year - 1; y >= this.settings.allYears[0]; --y) {
      if (user.rankingsSubmitted?.includes(y)) {
        const ranking = await this.db.collection<TerpecaRanking>('rankings').doc(`${user.uid}-${y}-${category}`).ref
        .get().then(prevDoc => (prevDoc.exists ? <TerpecaRanking>prevDoc.data() : null));
        if (ranking) {
          if (!prevRanking) {
            prevRanking = ranking;
          }
          if (ranking.unsortedIds) {
            for (const roomId of ranking.unsortedIds) {
              switch (category) {
                case TerpecaCategory.TOP_ROOM:
                  this.regularPlayedIds.add(roomId);
                  break;
                case TerpecaCategory.TOP_ONLINE_ROOM:
                  this.onlinePlayedIds.add(roomId);
                  break;
              }
            }
          }
        }
      }
    }
    if (!prevRanking) {
      console.log(`no previous rankings found for category ${category}`);
    }
    return prevRanking;
  }

  private async loadRankings(user: TerpecaUser, category: TerpecaCategory) {
    const docId = `${user.uid}-${this.year}-${category}`;
    const doc: AngularFirestoreDocument<TerpecaRanking> = this.db.collection<TerpecaRanking>('rankings').doc(docId);
    const subscription: Subscription = doc.valueChanges()
    .subscribe(async (storedRanking: TerpecaRanking) => {
      if (!storedRanking && !this.settings.isPastVotingDeadline()) {
        console.log(`creating initial ${this.year} ranking doc for category ${category}`);
        // We have to be super sure that the doc doesn't exist so we don't overwrite good data.
        await doc.ref.get().then(async d => {
          if (!d.exists) {
            // We load any nominations in this category to use to help seed the rooms played.
            const newRanking: TerpecaRanking = {
              createTime: <app.firestore.Timestamp>app.firestore.FieldValue.serverTimestamp(),
              userId: user.uid,
              year: environment.currentAwardYear,
              category,
              horrorPreference: user.horrorPreference || HorrorPreference.UNKNOWN,
              unsortedIds: [],
              rankedIds: [],
              unrankedReasonMap: {},
              submitted: false,
              shadowbanned: user.shadowbanned || false
            };
            // When creating a new year's ranking, we use the last ranking of the same type to seed the data.
            const prevRanking = (category === TerpecaCategory.TOP_ROOM ? this.previousRegularRanking : this.previousOnlineRanking);
            if (prevRanking && prevRanking.unsortedIds && prevRanking.rankedIds) {
              newRanking.unsortedIds = [...prevRanking.unsortedIds].filter(roomId => this.rankableRoomMap.has(roomId));
              newRanking.rankedIds = [...prevRanking.rankedIds].filter(roomId => this.rankableRoomMap.has(roomId));
              const prevUnrankedIds = getUnrankedIds(prevRanking).filter(roomId => this.rankableRoomMap.has(roomId));
              newRanking.unrankedReasonMap = {};
              for (const roomId of Object.keys(prevRanking.unrankedReasonMap || {})) {
                if (prevUnrankedIds.includes(roomId)) {
                  newRanking.unrankedReasonMap[roomId] = prevRanking.unrankedReasonMap[roomId];
                }
              }
              newRanking.seededFrom = prevRanking.year;
              console.log(`seeding rankings for category ${category} from ${prevRanking.year}`);
            }
            // We also add any nominated rooms to the rooms played list if they haven't been added yet.
            const nominatedRoomIds = (category === TerpecaCategory.TOP_ROOM ? this.regularNominationIds : this.onlineNominationsIds);
            let nominatedRoomsAdded = 0;
            for (const roomId of nominatedRoomIds) {
              if (this.rankableRoomMap.has(roomId) && !newRanking.unsortedIds.includes(roomId)) {
                newRanking.unsortedIds.push(roomId);
                nominatedRoomsAdded += 1;
              }
            }
            if (nominatedRoomsAdded > 0) {
              console.log(`added ${nominatedRoomsAdded} nominated rooms to the played list for category ${category}`);
            }
            // Finally we add any unaffiliated rooms nominated or ranked in past years if they haven't been added yet.
            const playedIds = (category === TerpecaCategory.TOP_ROOM ? this.regularPlayedIds : this.onlinePlayedIds);
            let pastRoomsAdded = 0;
            for (const roomId of playedIds.keys()) {
              if (this.rankableRoomMap.has(roomId) && !this.isAffiliatedRoom(roomId) && !newRanking.unsortedIds.includes(roomId)) {
                newRanking.unsortedIds.push(roomId);
                pastRoomsAdded += 1;
              }
            }
            if (pastRoomsAdded > 0) {
              console.log(`added ${pastRoomsAdded} previously ranked or nominated rooms to the played list for category ${category}`);
            }
            await doc.set(newRanking);
          }
        });
        return;
      }
      switch (category) {
        case TerpecaCategory.TOP_ROOM:
          this.regularRanking = storedRanking;
          break;
        case TerpecaCategory.TOP_ONLINE_ROOM:
          this.onlineRanking = storedRanking;
          break;
      }
    });
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        this.regularRankingDoc = doc;
        this.regularRankingSubscription = subscription;
        break;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        this.onlineRankingDoc = doc;
        this.onlineRankingSubscription = subscription;
        break;
    }
  }

  lastVoterYear() {
    return utils.lastVoterYear(this.user, this.year);
  }

  nominatedThisYear() {
    return this.user.nominationsSubmitted?.includes(this.year);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    if (this.regularRankingSubscription) {
      this.regularRankingSubscription.unsubscribe();
    }
    if (this.onlineRankingSubscription) {
      this.onlineRankingSubscription.unsubscribe();
    }
  }

  toggleRules() {
    this.hideRules = !this.hideRules;
    window.scroll({
      top: 0,
      left: 0
    });
    if (this.hideRules && !this.user.rankingRulesConfirmed?.includes(this.year)) {
      this.auth.confirmRankingRules();
    }
  }

  async stepChanged(event: StepperSelectionEvent) {
    this.currentSortFn = this.roomCountrySort;
    this.readyToRank = false;
    if (event.selectedIndex === this.regularRankingStepIndex) {
      await this.setupRankings(TerpecaCategory.TOP_ROOM);
    } else if (event.selectedIndex === this.onlineRankingStepIndex) {
      await this.setupRankings(TerpecaCategory.TOP_ONLINE_ROOM);
    } else if (event.selectedIndex === this.reviewAndSubmitStepIndex) {
      this.rankingErrors = [];
      this.validateRankings(TerpecaCategory.TOP_ROOM);
      if (this.settings.hasOnlineRoomCategory()) {
        this.validateRankings(TerpecaCategory.TOP_ONLINE_ROOM);
      }
    }
    this.furthestStepReached = Math.max(this.furthestStepReached, event.selectedIndex);
  }

  private async setupRankings(category: TerpecaCategory) {
    this.rankingNotes = [];
    let ranking: TerpecaRanking;
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        ranking = this.regularRanking;
        break;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        ranking = this.onlineRanking;
        break;
    }
    if (!ranking) {
      console.log('Something went wrong - no ranking object loaded!');
      return;
    }
    this.currentCategory = category;
    this.selectedRoomIds = ranking.unsortedIds ? [...ranking.unsortedIds] : [];
    this.unrankedRoomIds = getUnrankedIds(ranking);
    this.unrankedReasonMap = ranking.unrankedReasonMap || { };
    if (this.selectedRoomIds.length > 0 && (!ranking.rankedIds || ranking.rankedIds.length === 0)) {
      this.rankedRoomIds = [...this.selectedRoomIds.filter(id => !this.unrankedRoomIds.includes(id))];
      this.randomizeInPlace(this.rankedRoomIds);
      this.rankingNotes.push(
        'The rooms have initially been randomized to avoid systematic bias in the rankings due to initial sort order.');
    } else {
      this.rankedRoomIds = ranking.rankedIds ? [...ranking.rankedIds] : [];
      const roomsToAdd = [];
      for (const id of this.selectedRoomIds) {
        if (!this.rankedRoomIds.includes(id) && !this.unrankedRoomIds.includes(id)) {
          roomsToAdd.push(id);
        }
      }
      if (roomsToAdd.length > 0) {
        this.randomizeInPlace(roomsToAdd);
        this.rankedRoomIds = roomsToAdd.concat(this.rankedRoomIds);
        this.rankingNotes.push(
          'You have one or more new rooms to rank; they have been randomized and added to the top of the list.');
      }
      let roomsDeleted = false;
      for (const id of this.rankedRoomIds) {
        if (!this.selectedRoomIds.includes(id)) {
          this.rankedRoomIds = this.rankedRoomIds.filter(item => item !== id);
          roomsDeleted = true;
        }
      }
      for (const id of this.unrankedRoomIds) {
        if (!this.selectedRoomIds.includes(id)) {
          this.unrankedRoomIds = this.unrankedRoomIds.filter(item => item !== id);
          roomsDeleted = true;
        }
      }
      if (roomsDeleted) {
        this.rankingNotes.push(
          'One or more rooms you previously ranked was removed from your played rooms list, and thus has been removed from the list.');
      }
      roomsDeleted = false;
      for (const id of this.rankedRoomIds) {
        if (!this.rankableRoomMap.has(id)) {
          this.rankedRoomIds = this.rankedRoomIds.filter(item => item !== id);
          roomsDeleted = true;
        }
      }
      for (const id of this.unrankedRoomIds) {
        if (!this.rankableRoomMap.has(id)) {
          this.unrankedRoomIds = this.unrankedRoomIds.filter(item => item !== id);
          roomsDeleted = true;
        }
      }
      if (roomsDeleted) {
        this.rankingNotes.push(
          'One or more rooms you previously ranked is no longer eligible to be ranked and has been removed from the list.');
      }
    }
    // It is possible that a room was removed from the unrankedRoomIds list without having been removed from the unrankedReasonMap
    // before we get here, so we fix that now.
    let reasonMapCleanedUp = false;
    for (const id of Object.keys(this.unrankedReasonMap)) {
      if (!this.unrankedRoomIds?.includes(id)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.unrankedReasonMap[id];
        reasonMapCleanedUp = true;
      }
    }
    if (this.rankingNotes.length > 0 || reasonMapCleanedUp) {
      await this.saveRankingData();
    }
    this.readyToRank = true;
  }

  async saveApplicationData() {
    if (!this.firstFormGroup.valid) {
      return;
    }
    await this.auth.updateUser(this.firstFormGroup.value);
    this.firstFormGroup.markAsPristine();
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  private randomizeInPlace(arr: string[]) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
  }

  isAffiliatedRoom(roomId: string) {
    return this.user.affiliatedRoomIds && this.user.affiliatedRoomIds.includes(roomId);
  }

  affiliationsDisclosed() {
    return this.user.affiliatedRoomIds;
  }

  hasAffiliatedRooms() {
    return this.user.affiliatedRoomIds && this.user.affiliatedRoomIds.length > 0;
  }

  numAffiliatedRooms() {
    return this.user.affiliatedRoomIds ? this.user.affiliatedRoomIds.length : 0;
  }

  toggleAffiliatedRoom(roomId: string) {
    if (!this.isAffiliatedRoom(roomId)) {
      this.markAffiliated(roomId);
    } else {
      this.markUnaffiliated(roomId);
    }
    this.secondFormGroup.markAsDirty();
  }

  affiliatedRooms() {
    const affiliatedRooms: TerpecaRoom[] = [];
    if (this.hasAffiliatedRooms()) {
      for (const room of this.allRooms) {
        if (room.docId && this.user.affiliatedRoomIds.includes(room.docId)) {
          affiliatedRooms.push(room);
        }
      }
    }
    affiliatedRooms.sort(this.roomCountrySort);
    return affiliatedRooms;
  }

  async markAffiliated(roomId: string) {
    this.removeRoomId(roomId);
    const batch = this.db.firestore.batch();
    batch.update(this.db.firestore.collection('rooms').doc(roomId), {
      affiliatedUserIds: <string[]><unknown>app.firestore.FieldValue.arrayUnion(this.user.uid)
    });
    batch.update(this.db.firestore.collection('users').doc(this.user.uid), {
      affiliatedRoomIds: <string[]><unknown>app.firestore.FieldValue.arrayUnion(roomId)
    });
    batch.update(this.regularRankingDoc.ref, {
      unsortedIds: <string[]><unknown>app.firestore.FieldValue.arrayRemove(roomId),
      rankedIds: <string[]><unknown>app.firestore.FieldValue.arrayRemove(roomId)
    });
    if (this.onlineRankingDoc) {
      batch.update(this.onlineRankingDoc.ref, {
        unsortedIds: <string[]><unknown>app.firestore.FieldValue.arrayRemove(roomId),
        rankedIds: <string[]><unknown>app.firestore.FieldValue.arrayRemove(roomId)
      });
    }
    await batch.commit();
  }

  async markUnaffiliated(roomId: string) {
    const batch = this.db.firestore.batch();
    batch.update(this.db.firestore.collection('rooms').doc(roomId), {
      affiliatedUserIds: <string[]><unknown>app.firestore.FieldValue.arrayRemove(this.user.uid)
    });
    batch.update(this.db.firestore.collection('users').doc(this.user.uid), {
      affiliatedRoomIds: <string[]><unknown>app.firestore.FieldValue.arrayRemove(roomId)
    });
    await batch.commit();
  }

  async confirmAffiliations() {
    if (!this.hasAffiliatedRooms()) {
      await this.db.firestore.collection('users').doc(this.user.uid).update({
        affiliatedRoomIds: []
      });
    }
    const dialogRef = this.dialog.open(ConfirmListDialogComponent, {
      data: {
        category: 'Affiliations',
        confirmNoneInstructions: 'Please confirm that you have no rooms in the list with which you are affiliated.',
        confirmInstructions: 'Please confirm that the following is a complete list of the rooms with which you are affiliated.',
        roomList: this.affiliatedRooms(),
        numbered: false
      }
    });
    const subscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.secondFormGroup.markAsPristine();
        this.stepper.selected.completed = true;
        this.stepper.next();
      }
      subscription.unsubscribe();
    });
  }

  private getFirestoreDoc(category: TerpecaCategory): AngularFirestoreDocument<TerpecaRanking> {
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        return this.regularRankingDoc;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        return this.onlineRankingDoc;
    }
  }

  private getRanking(category: TerpecaCategory): TerpecaRanking {
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        return this.regularRanking;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        return this.onlineRanking;
    }
  }

  private getPreviousRanking(category: TerpecaCategory): TerpecaRanking {
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        return this.previousRegularRanking;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        return this.previousOnlineRanking;
    }
  }

  maxResultsToShow() {
    return !this.secondFormGroup.value.countryFilter && !this.secondFormGroup.value.searchText && this.allRooms?.length > 100 ? 100 : 0;
  }

  roomsSelected(category: TerpecaCategory) {
    const ranking: TerpecaRanking = this.getRanking(category);
    return ranking && ranking.unsortedIds;
  }

  isPlayedRoom(category: TerpecaCategory, roomId: string) {
    const ranking: TerpecaRanking = this.getRanking(category);
    return ranking && ranking.unsortedIds && ranking.unsortedIds.includes(roomId);
  }

  hasPlayedRooms(category: TerpecaCategory) {
    const ranking: TerpecaRanking = this.getRanking(category);
    return ranking && ranking.unsortedIds && ranking.unsortedIds.length > 0;
  }

  numPlayedRooms(category: TerpecaCategory) {
    const ranking: TerpecaRanking = this.getRanking(category);
    return ranking && ranking.unsortedIds ? ranking.unsortedIds.length : 0;
  }

  roomSelectLabel(roomId: string) {
    if (this.isAffiliatedRoom(roomId)) {
      return 'Affiliated';
    }
    return 'Mark Played';
  }

  roomUnselectLabel(roomId: string) {
    if (this.isAffiliatedRoom(roomId)) {
      return 'Affiliated';
    }
    return 'Mark Unplayed';
  }

  togglePlayedRoom(category: TerpecaCategory, roomId: string) {
    if (!this.isPlayedRoom(category, roomId)) {
      this.markPlayed(category, roomId);
    } else {
      this.markUnplayed(category, roomId);
    }
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        this.thirdFormGroup.markAsDirty();
        break;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        this.fifthFormGroup.markAsDirty();
        break;
    }
  }

  playedRooms(category: TerpecaCategory) {
    const ranking: TerpecaRanking = this.getRanking(category);
    const playedRooms: TerpecaRoom[] = [];
    if (this.hasPlayedRooms(category)) {
      for (const room of this.allRooms) {
        if (room.docId && ranking.unsortedIds.includes(room.docId)) {
          playedRooms.push(room);
        }
      }
    }
    playedRooms.sort(this.roomCountrySort);
    return playedRooms;
  }

  quotableRooms() {
    return this.regularRanking?.rankedIds?.slice(0, maxQuotesPerYear);
  }

  showNewLogo(roomId: string) {
    const previousRanking = this.getPreviousRanking(this.currentCategory);
    if (!previousRanking || !previousRanking.submitted) {
      // In this case, all rooms are new, so the distinction isn't helpful.
      return false;
    }
    return !previousRanking.rankedIds?.includes(roomId);
  }

  getUnrankedEmoji(category: TerpecaCategory, roomId: string) {
    const ranking: TerpecaRanking = this.getRanking(category);
    return ranking?.unrankedReasonMap ? getUnrankedReasonEmoji(ranking.unrankedReasonMap[roomId]) : '';
  }

  getUnrankedReason(category: TerpecaCategory, roomId: string) {
    const ranking: TerpecaRanking = this.getRanking(category);
    return ranking?.unrankedReasonMap ? getUnrankedReasonDescription(ranking.unrankedReasonMap[roomId]) : '';
  }

  async markPlayed(category: TerpecaCategory, roomId: string) {
    await this.getFirestoreDoc(category).update({
      unsortedIds: <string[]><unknown>app.firestore.FieldValue.arrayUnion(roomId)
    });
  }

  async markUnplayed(category: TerpecaCategory, roomId: string) {
    await this.getFirestoreDoc(category).update({
      unsortedIds: <string[]><unknown>app.firestore.FieldValue.arrayRemove(roomId)
    });
  }

  async confirmPlayedRooms(category: TerpecaCategory) {
    if (!this.hasPlayedRooms(category)) {
      await this.getFirestoreDoc(category).update({
        unsortedIds: []
      });
    }
    const onlinePrefix = category === TerpecaCategory.TOP_ONLINE_ROOM ? 'online ' : '';
    const dialogRef = this.dialog.open(ConfirmListDialogComponent, {
      data: {
        category: `${ category === TerpecaCategory.TOP_ONLINE_ROOM ? 'Online ' : ''}Rooms Played`,
        confirmNoneInstructions: `Please confirm that you have not played any ${ onlinePrefix }rooms that were listed as finalists.`,
        confirmInstructions: `Please confirm that the following is the complete list of finalist ${ onlinePrefix }rooms that you have played.`,
        roomList: this.playedRooms(category),
        numbered: false
      }
    });
    const subscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (category) {
          case TerpecaCategory.TOP_ROOM:
            this.thirdFormGroup.markAsPristine();
            break;
          case TerpecaCategory.TOP_ONLINE_ROOM:
            this.fifthFormGroup.markAsPristine();
            break;
        }
        this.stepper.selected.completed = true;
        this.stepper.next();
      }
      subscription.unsubscribe();
    });
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rankedRoomIds, event.previousIndex, event.currentIndex);
    await this.saveRankingData();
  }

  toggleRoomRanked(roomId: string) {
    const room = this.rankableRoomMap.get(roomId);
    if (this.rankedRoomIds.includes(roomId)) {
      const data: any = {
        ranker: this,
        room: room
      };
      this.dialog.open(UnrankRoomDialogComponent, { data });
    } else {
      this.rerankRoom(room);
    }
  }

  private removeRoomId(roomId: string) {
    if (this.selectedRoomIds) {
      this.selectedRoomIds = this.selectedRoomIds.filter(rid => rid !== roomId);
    }
    if (this.rankedRoomIds) {
      this.rankedRoomIds = this.rankedRoomIds.filter(rid => rid !== roomId);
    }
    if (this.unrankedRoomIds) {
      this.unrankedRoomIds = this.unrankedRoomIds.filter(rid => rid !== roomId);
    }
    if (this.unrankedReasonMap && this.unrankedReasonMap[roomId]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.unrankedReasonMap[roomId];
    }
  }

  async unrankRoom(room: TerpecaRoom, reason: UnrankedReasonEntry) {
    if (!this.rankedRoomIds.includes(room.docId)) {
      return;
    }
    if (reason.reason === UnrankedReason.DID_NOT_PLAY) {
      // In this case we just want to remove it entirely from all the lists.
      this.removeRoomId(room.docId);
    } else if (reason.reason === UnrankedReason.CONFLICT_OF_INTEREST) {
      // In this case, we mark the affiliation too, which also removes it from all the lists.
      await this.markAffiliated(room.docId);
    } else {
      // For all other reasons, we keep it in the list but move it to the unranked section.
      this.rankedRoomIds = this.rankedRoomIds.filter(rid => rid !== room.docId);
      if (!this.unrankedRoomIds.includes(room.docId)) {
        this.unrankedRoomIds.push(room.docId);
        this.unrankedRoomIds.sort(compareUsingMap(this.rankableRoomMap, compareEntitiesByLocation));
      }
      this.unrankedReasonMap[room.docId] = reason;
    }
    await this.saveRankingData();
  }

  async rerankRoom(room: TerpecaRoom) {
    if (!this.rankedRoomIds.includes(room.docId)) {
      this.rankedRoomIds.push(room.docId);
    }
    if (this.unrankedRoomIds.includes(room.docId)) {
      this.unrankedRoomIds = this.unrankedRoomIds.filter(rid => rid !== room.docId);
    }
    if (this.unrankedReasonMap[room.docId]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.unrankedReasonMap[room.docId];
    }
    await this.saveRankingData();
  }

  async saveRankingData() {
    switch (this.currentCategory) {
      case TerpecaCategory.TOP_ROOM:
        this.fourthFormGroup.markAsDirty();
        break;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        this.sixthFormGroup.markAsDirty();
        break;
    }
    await this.getFirestoreDoc(this.currentCategory).update({
      horrorPreference: this.user.horrorPreference || HorrorPreference.UNKNOWN,
      unsortedIds: this.selectedRoomIds,
      rankedIds: this.rankedRoomIds,
      unrankedReasonMap: this.unrankedReasonMap
    });
    switch (this.currentCategory) {
      case TerpecaCategory.TOP_ROOM:
        this.fourthFormGroup.markAsPristine();
        break;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        this.sixthFormGroup.markAsPristine();
        break;
    }
  }

  validateRankings(category: TerpecaCategory) {
    const ranking: TerpecaRanking = category === TerpecaCategory.TOP_ROOM ? this.regularRanking : this.onlineRanking;
    const nominationIds: string[] = category === TerpecaCategory.TOP_ROOM ? this.regularNominationIds : this.onlineNominationsIds;
    const numUnsorted = ranking.unsortedIds ? ranking.unsortedIds.length : 0;
    const numRanked = ranking.rankedIds ? ranking.rankedIds.length : 0;
    const numUnranked = getUnrankedIds(ranking).length || 0;
    const categoryDescriptor = category === TerpecaCategory.TOP_ROOM ? 'in-person' : 'online';
    const categoryRankStep = category === TerpecaCategory.TOP_ROOM ? 4 : 6;
    if (numUnsorted > numRanked + numUnranked) {
      this.rankingErrors.push(`Not all selected ${categoryDescriptor} rooms were ranked, please revisit Step ${categoryRankStep}.`);
    }
    if (numUnsorted < numRanked + numUnranked) {
      this.rankingErrors.push(`More ${categoryDescriptor} rooms were ranked than you played, please revisit Step ${categoryRankStep}.`);
    }
    if (ranking.unsortedIds) {
      for (const roomId of ranking.unsortedIds) {
        const room = this.rankableRoomMap.get(roomId);
        if (!room) {
          this.rankingErrors.push(`One of or more of the ${categoryDescriptor} rooms you selected is no longer rankable, please revisit Step ${categoryRankStep}.`);
          break;
        }
      }
    }
    if (ranking.rankedIds) {
      for (const roomId of ranking.rankedIds) {
        const room = this.rankableRoomMap.get(roomId);
        if (!room) {
          this.rankingErrors.push(`One of or more of the ${categoryDescriptor} rooms you ranked is no longer rankable, please revisit Step ${categoryRankStep}.`);
          break;
        }
        if (!ranking.unsortedIds || !ranking.unsortedIds.includes(roomId)) {
          this.rankingErrors.push(`One of or more of the ${categoryDescriptor} rooms you selected has not been ranked, please revisit Step ${categoryRankStep}.`);
          break;
        }
      }
    }
    for (const roomId of nominationIds) {
      if (this.rankableRoomMap.get(roomId) && (!ranking.unsortedIds || !ranking.unsortedIds.includes(roomId))) {
        this.rankingErrors.push(`One of or more of the ${categoryDescriptor} rooms you nominated has not been ranked, please revisit Step ${categoryRankStep - 1}.`);
        break;
      }
    }
  }

  async confirmSubmitRankings() {
    this.dialog.open(SimpleDialogComponent, {
      data: {
        title: 'Confirm Your Rankings',
        message: 'IMPORTANT: By submitting these rankings, you are publicly acknowledging that:' +
        '<ul><li>you have personally played all the rooms you have ranked</li>' +
        '<li>you do not have any conflict of interest related to the games you have ranked</li></ul>' +
        'Note that if it is found later that you are not acting in good faith and have chosen to rank games for any other reason than ' +
        'helping the project, you will be disqualified from further participation in the project and your actions may damage the reputation ' +
        'of yourself and any company you may be associated with.',
        okCallback: () => { this.submitRankings(); },
        okLabel: 'Confirm and Submit',
      }
    });
  }

  async submitRankings() {
    await this.auth.submitRankings();
    window.scrollTo(0, 0);
  }

  async reopenRankings() {
    await this.auth.reopenRankings();
    this.furthestStepReached = this.reviewAndSubmitStepIndex;
    window.scrollTo(0, 0);
  }
}
